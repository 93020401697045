import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';

import styles from "./Price.module.scss"

const Price = ({ className, price }) => {
  const cls = classNames(
    styles.price,
    className
  );

  return (
    <span className={ cls }>
      {
        new Array(3).fill(0).map((v, idx) => {
          return <span className={`${ idx < price ? styles.active : '' }`} key={ idx }>£</span>;
        })
      }
    </span>
  )
};

Price.propTypes = {
  price: PropTypes.number
}

export default Price
