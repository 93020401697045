import React from 'react';

import styles from './Credits.module.scss';

const Credits = ({ className }) => {
  return (
    <div className={`${styles.credits} ${className}`}>
      <p>
        Project created by <a href="http://www.luisacharles.com" target="_blank" rel="noreferrer noopener">Luisa Charles</a> & <a href="https://www.arts.ac.uk/colleges/london-college-of-communication" target="_blank" rel="noreferrer noopener">UAL:LCC</a><br/>
        Supported by <a href="https://www.southwark.gov.uk" target="_blank" rel="noreferrer noopener">Southwark Council</a>
      </p>
      <p>
        Design by <a href="http://www.peter-roden.com/" target="_blank" rel="noreferrer noopener">Peter Roden</a><br/>
        Developed by <a href="https://jthaw.me" target="_blank" rel="noreferrer noopener">Jonny Thaw</a>
      </p>
    </div>
  )
};

export default Credits;
