import React from "react"
// import PropTypes from "prop-types"
import classNames from 'classnames';

import Icon from './Icon/Icon';

import styles from "./NumberCircle.module.scss"

const NumberCircle = ({ className, number }) => {
  const cls = classNames(
    styles.number,
    className
  );

  return (
    <div className={cls}>
      <Icon className={styles.numberIcon} icon="pr-circle" size="free"/>
      <span className={styles.numberText}>{ number.toString().padStart(2, '0') }</span>
    </div>
  )
};

NumberCircle.propTypes = {
}

export default NumberCircle
